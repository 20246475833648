import { computed, type Ref } from 'vue';
import type { Element as ElementType, Section } from '@shared/types/model';
import type { Directions, ScreenSize, Spacing } from '@shared/types/options';

type DefaultObj = Record<'padding' | 'margin', Spacing>;

export function prepareSpacingObject(spacing?: Spacing) {
  return Object.entries(spacing || {}).reduce((acc, item) => {
    const screenSize = item[0] as ScreenSize;
    const breaks = item[1];

    acc[screenSize] = Object.entries(breaks).reduce((acc2, item2) => {
      const dir = item2[0] as Directions;
      const val = item2[1];
      acc2[dir] = `${val}px`;
      return acc2;
    }, {} as Record<Directions, string>);

    return acc;
  }, {} as Record<ScreenSize, Record<Directions, string>>);
}

export function usePadding(element: Ref<ElementType<'text' | 'conditional-text' | 'endingprogress'> | Section>, defaultObj?: DefaultObj) {
  return computed(() => prepareSpacingObject(
    (element.value as Section).options.spacing ||
    (element.value as ElementType<'text' | 'conditional-text' | 'endingprogress'>).options.padding ||
    defaultObj?.padding
  ));
}

export function useMargin(element: Ref<ElementType<'text' | 'conditional-text' | 'endingprogress' | 'image'> | Section>, defaultObj?: DefaultObj) {
  return computed(() => prepareSpacingObject(element.value.options.margin || defaultObj?.margin))
}

export function useSpacing(element: Ref<ElementType<'text' | 'conditional-text' | 'endingprogress' | 'image'> | Section>, defaultObj?: DefaultObj) {
  return {
    padding: usePadding(element as Ref<ElementType<'text' | 'conditional-text' | 'endingprogress'> | Section>, defaultObj),
    margin: useMargin(element, defaultObj)
  }
}
